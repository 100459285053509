import React, { FC, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { SpringConfig, config } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import Card from '../components/Card/CardView';
import './tour.css'

import { getImageByRelativePath, isBrowser } from '../shared/utilities';
import useWindowDimensions from '../hooks/useWindowDimensions';

const Carousel = isBrowser() ? require('react-spring-3d-carousel').default : null

type CarouselProps = {
    goToSlide: number,
    offsetRadius: number,
    showNavigation: boolean,
    config: SpringConfig
}



const TourPage: FC = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'tourPage' });
    const [state, setState] = useState<CarouselProps>({
        goToSlide: 0,
        offsetRadius: 2,
        showNavigation: false,
        config: config.gentle
    });

    const {width} = useWindowDimensions();
    function getBanner(){
        var banner 
        if (width > 768) {
            banner = <StaticImage
            className={'banner'}
                src="../images/banner5.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
    
        }
        else{
            banner = <StaticImage
            className={'banner'}
                src="../images/banner5_mobile.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }
    const banner = getBanner();

    const data: any = useStaticQuery(graphql`
    {
        Test: file(relativePath: {eq: "quito.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test2: file(relativePath: {eq: "banos.jpg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test3: file(relativePath: {eq: "andes.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
         Test5: file(relativePath: {eq: "amazone.jpeg"}) {
         childImageSharp {
           gatsbyImageData(width: 900)
         }
         relativePath
       }
     
     }
    `);


    let slides = [
        {
            key: uuidv4(),
            content: <Card text={t("quito")} size={900} image={getImageByRelativePath("quito.jpeg", data)} header_text='Quito' />
        },
        {
            key: uuidv4(),
            content: <Card text={t("banos")} size={900} image={getImageByRelativePath("banos.jpg", data)} header_text='Baños' />
        },
        {
            key: uuidv4(),
            content: <Card text={t("andes")} size={900} image={getImageByRelativePath("andes.jpeg", data)} header_text='Andes' />
        },
        {
            key: uuidv4(),
            content: <Card text={t("amazone")} size={900} image={getImageByRelativePath("amazone.jpeg", data)} header_text='Amazone' />
        },
    ].map((slide, index) => {
        return { ...slide, onClick: () => setState({ ...state, goToSlide: index }) };
    });

    return (
        <Layout>
            <Seo title={"Rondreis door Ecuador"} description={"Combineer Galapagos met een rondreis door de Amazone en het Andesgebergte."} />
            {banner}


            <div style={{
                    textAlign: 'center',
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1.5rem 0 1.5rem 0',
                    boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                    zIndex: '200',
                    backgroundColor: '#ffffff',
                    flexDirection: 'column'
                }}>


                    <div className='main_text'>
                        {t("tourIntro")}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>{t("tourButton")}</Link>
                        </div>
                    </div>
                    
                    
                </div>


            <div className='carousel_container' style={{ width: '80%', height: '1000px', margin: '0 auto', paddingTop: '100px' }}>
                {isBrowser() ? <Carousel
                    slides={slides}
                    goToSlide={state.goToSlide}
                    offsetRadius={state.offsetRadius}
                    showNavigation={state.showNavigation}
                    animationConfig={state.config}
                /> : null}


                <div className='row slider_controller'>
                    <div onClick={() =>setState({ ...state, goToSlide: (state.goToSlide-1) })}>
                        <StaticImage
                        className='arrow'
                            src='../icons/arrow_backward.png'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                        />
                    </div>


                    <div onClick={() =>setState({ ...state, goToSlide: (state.goToSlide+1) })}>
                        <StaticImage
                        className='arrow'
                            src='../icons/arrow_forward.png'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                        />
                    </div>


                </div>


            </div>





            


            {/* <Card text='Bezoek de hoogste hoofdstad ter wereld: Quito. De stad ligt op de helling van de actieve vulkaan Pichincha. Wist je dat het één van de best bewaarde koloniale steden van Zuid-Amerika is? Ontdek de stad, loop op de evenaar, bezoek één van de omliggende nationale parken of breng een bezoek aan de grootste markt van Zuid-Amerika. ' image={getImage(data.allFile.nodes[4])!} header_text='Quito' /> */}

        </Layout>
    )
}

export default TourPage;